import { Box, Container, Grid } from '@mui/material';

import './WisexAdvantagesStyles.css';
import { SCALE_UP, WISEX_FACTOR, WISEX_LOGO_LIGHT, LOGO_ICON } from '../../../../../Utils/constants';
import RevealAnimation from '../../../../Utils/RevealAnimation';
import { motion } from 'framer-motion';

const WisexAdvantages = () => {
  return (
    <Box className="WisexAdvantagesContainer">
      <Container>
        <Box className="AdvantagesHeadingContainer">
          <Box className="AdvantagesHeadingTextContainer">
            <img loading='lazy' src={LOGO_ICON} alt="LOGO" />
            <p>Advantage</p>
          </Box>
        </Box>

        <Box className='WisexAdvantagesFactorContainer'>
          <Grid container >
            {WISEX_FACTOR.map(({ icon, label, text }, i) => (
              <Grid item xs={12} sm={6} md={3} key={i}>
                <div className='FactorCard InvestmentCard'>
                  <img loading='lazy' src={icon} alt="icon" width={50} height={50} />
                  <Box>
                    <p className='FactorCardLabel InvestmentCardText InvestmentCardLabel'>{label}</p>
                    <p className='FactorCardText InvestmentCardText InvestmentCardLTExt'>{text}</p>
                  </Box>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box >
  );
};

export default WisexAdvantages;
