import React, { useMemo, useState } from 'react';
import { Slider, TextField, Typography, Box, Paper, Grid, Container, Divider, Chip } from '@mui/material';
import Styles from './IncestmentCalculatorStyles.module.css';
import { LineChart } from '@mui/x-charts';
import zIndex from '@mui/material/styles/zIndex';




const InvestmentCalculator = ({ styles }) => {
    const initialRentalReturn = 4;
    const initialAppreciation = 3;
    const initialTenure = 3;


    const [investment, setInvestment] = useState(1000000);
    const [rentalReturn, setRentalReturn] = useState(initialRentalReturn);
    const [appreciation, setAppreciation] = useState(initialAppreciation);
    const [tenure, setTenure] = useState(initialTenure);


    // Calculate total rental return and appreciation over the dynamic tenure
    const totalRentalReturn = (investment * (rentalReturn / 100) * tenure).toFixed(2);
    const totalAppreciation = (investment * (appreciation / 100) * tenure).toFixed(2);

    // Calculate the final property value after the tenure
    const propertyValue = (investment + parseFloat(totalRentalReturn) + parseFloat(totalAppreciation)).toFixed(2);

    // Calculate the total ROI in ₹ (not as a percentage)
    const totalROI = (parseFloat(totalRentalReturn) + parseFloat(totalAppreciation)).toFixed(2);

    // Simplified Avg Annualized Return as the sum of rental return and appreciation rates
    const avgAnnualizedReturn = (parseFloat(rentalReturn) + parseFloat(appreciation)).toFixed(2);

    const totalProfitInPercentage = (((propertyValue - investment) / investment) * 100).toFixed(2);
    const monthlyRentalReturn = (totalRentalReturn / (tenure * 12)).toFixed(2);




    const { xAxisData, rentalReturnSeries, appreciationSeries } = useMemo(() => {
        const years = Array.from({ length: tenure + 1 }, (_, i) => i); // Only whole years from 0 to tenure (inclusive)
        const rentalReturnSeries = years.map(year => (investment * (rentalReturn / 100) * year).toFixed(2));
        const appreciationSeries = years.map(year => (investment * (appreciation / 100) * year).toFixed(2));

        return { xAxisData: years, rentalReturnSeries, appreciationSeries };
    }, [investment, rentalReturn, appreciation, tenure]);

    function formatNumberWithCommas(number) {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return (
        <Box className={Styles.InvestmentCalculatorContainer} sx={styles}>
            <Container>
                <Box className={Styles.InvestmentCalculatorWrapper}>
                    <h1>
                        Calculate Returns From Fractional Ownership Opportunities
                    </h1>
                    <Divider sx={{ mb: 3 }} />
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box className={Styles.InvestmentCalculatorTopContent}>
                                <p>
                                    Your portfolio of fractional commercial real estate helps your build long term wealth due to longer terms with stable blue chip tenants.
                                </p>
                                <p>
                                    Use the sliders or the fields to change the amounts or percentages.
                                </p>
                            </Box>
                            <Box className={Styles.InvestmentCalculatorInputWrapper}>
                                <label htmlFor="in_invest">Enter Investment Amount (Min ₹10 Lacs)</label>
                                <input
                                    name='in_invest'
                                    className={Styles.InvestmentCalculatorInput}
                                    value={`₹ ${investment}`}
                                    onChange={(e) => setInvestment(parseFloat(e.target.value.replace('₹ ', '')) || 0)}
                                />
                            </Box>
                            <Box className={Styles.IncomeCalculatorSliderWrapper}>
                                <Box className={Styles.IncomeCalculatorSliderLabelContainer}>
                                    <h3>Average Annual Rental Returns %</h3>
                                    <Box className={Styles.RentalReturnInputContainer}>
                                        <input
                                            type="text"
                                            value={rentalReturn}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^\d*\.?\d*$/;
                                                if (regex.test(value)) {
                                                    const numericValue = parseFloat(value);

                                                    // Allow empty input or valid numbers up to 12%
                                                    if (value === '' || (!isNaN(numericValue) && numericValue <= 12)) {
                                                        setRentalReturn(value);
                                                    }
                                                }
                                            }}
                                        />
                                        <p>%</p>
                                    </Box>
                                </Box>
                                <Slider
                                    value={rentalReturn}
                                    onChange={(e, newValue) => setRentalReturn(newValue)}
                                    min={4}
                                    max={12}
                                    step={0.5}
                                    valueLabelDisplay="auto"
                                    marks
                                />
                                <Box className={Styles.IncomeCalculatorSliderMinMaxContainer}>
                                    <p>4.00%</p>
                                    <p>12.00%</p>
                                </Box>
                            </Box>
                            <Box className={Styles.IncomeCalculatorSliderWrapper}>
                                <Box className={Styles.IncomeCalculatorSliderLabelContainer}>
                                    <h3 >Expected Annual Appreciation %</h3>
                                    <Box className={Styles.RentalReturnInputContainer}>
                                        <input
                                            type="text"
                                            value={appreciation}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^\d*\.?\d*$/;
                                                if (regex.test(value)) {
                                                    const numericValue = parseFloat(value);

                                                    // Allow empty input or valid numbers up to 12%
                                                    if (value === '' || (!isNaN(numericValue) && numericValue <= 12)) {
                                                        setAppreciation(value);
                                                    }
                                                }
                                            }}
                                        />
                                        <p>%</p>
                                    </Box>
                                </Box>
                                <Slider
                                    value={appreciation}
                                    onChange={(e, newValue) => setAppreciation(newValue)}
                                    min={2}
                                    max={8}
                                    step={0.5}
                                    valueLabelDisplay="auto"
                                    marks
                                />
                                <Box className={Styles.IncomeCalculatorSliderMinMaxContainer}>
                                    <p>2.00%</p>
                                    <p>8.00%</p>
                                </Box>
                            </Box>
                            <Box className={Styles.IncomeCalculatorSliderWrapper}>
                                <Box className={Styles.IncomeCalculatorSliderLabelContainer}>
                                    <Box>
                                        <h3 >Tenure of Investment </h3><small>(Recommended 5 years)</small>
                                    </Box>
                                    <Box className={Styles.RentalReturnInputContainer}>
                                        <input
                                            type="text"
                                            value={tenure}
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value) || 0;
                                                if (!isNaN(value) && value <= 10) {
                                                    setTenure(value);
                                                }
                                            }}
                                        />
                                        <p>Years</p>
                                    </Box>
                                </Box>

                                <Slider
                                    value={tenure}
                                    onChange={(e, newValue) => setTenure(newValue)}
                                    min={3}
                                    max={10}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    marks
                                />
                                <Box className={Styles.IncomeCalculatorSliderMinMaxContainer}>
                                    <p>3 Years</p>
                                    <p>10 Years</p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className={Styles.LineChartDetailsContainer}>
                                <Box className={Styles.LineChartDetailsWrapper}>
                                    <small>Total Income from Rent</small>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                        <h2>₹ {formatNumberWithCommas(totalRentalReturn)}</h2>
                                        <Chip className={Styles.TotalProfitInPercentageChip} label={`₹ ${formatNumberWithCommas(monthlyRentalReturn)} monthly`} />
                                    </Box>
                                </Box>
                                <Box className={Styles.LineChartDetailsWrapper}>
                                    <small>Total Capital Appreciation (At Exit)</small>
                                    <h2>₹ {formatNumberWithCommas(totalAppreciation)}</h2>
                                </Box>
                            </Box>
                            <Box className={Styles.LineChartContainer} >
                                <LineChart
                                    xAxis={[
                                        {
                                            data: xAxisData,
                                            valueFormatter: (year) => `${year} Year`,
                                            tickCount: tenure + 1,
                                            grid: { stroke: '#e0e0e0', strokeWidth: 1 }, // Background grid lines
                                            type: 'piecewise',

                                        },
                                    ]}
                                    yAxis={[
                                        {
                                            grid: { stroke: '#e0e0e0', strokeWidth: 1 }, // Horizontal grid lines
                                        },
                                    ]}
                                    series={[
                                        {
                                            label: "RENTAL RETURN",
                                            data: rentalReturnSeries,
                                            color: '#132a5666',
                                            area: true,
                                            // showMark: false,
                                        },
                                        {
                                            label: "CAPITAL APPRECIATION",
                                            data: appreciationSeries,
                                            area: true,
                                            // showMark: false,
                                            color: '#00bf8d30'


                                        },
                                    ]}
                                    height={200}
                                    width={500}
                                    grid={{ horizontal: true }}

                                    sx={{
                                        "& .MuiChartsAxis-root.MuiChartsAxis-directionY": {
                                            display: "none",
                                        },
                                        "& .MuiChartsAxis-root.MuiChartsAxis-directionX .MuiChartsAxis-tickContainer": {
                                            "&:nth-of-type(even)": {
                                                display: "none",
                                            },
                                        },
                                        "& .MuiAreaElement-root.css-1bdz2wt-MuiAreaElement-root": {
                                            fill: '#98ceebd4',
                                            stroke: '#102348'
                                        },
                                        "& .MuiAreaElement-root.css-18smze5-MuiAreaElement-root": {

                                            fill: '#00e4a952',
                                            stroke: '#00C08D'
                                        },
                                        "& .MuiChartsLegend-row": {
                                            display: 'none'
                                        }
                                    }}
                                />
                                <Box className={Styles.BackgroundGridSVGContainer}>
                                    <svg width="100%" height="100%">
                                        {Array.from({ length: 3 }).map((_, i) => (
                                            <line
                                                key={i}
                                                x1="0"
                                                y1={(i + 1) * 60}
                                                x2="100%"
                                                y2={(i + 1) * 60}
                                                stroke="#e0e0e0"
                                                strokeWidth="1"
                                            />
                                        ))}
                                    </svg>
                                </Box>
                            </Box>
                            <Divider sx={{ mt: 1 }} />
                            <Box className={Styles.LineChartBottomSection}>

                                <Box className={Styles.LineChartBottomSectionContainer} >
                                    <Box className={Styles.LineChartBottomSectionCard}>
                                        <p>Avg Annualized Return</p>
                                        <h2>{!isNaN(avgAnnualizedReturn) ? avgAnnualizedReturn : 0}%</h2>
                                    </Box>
                                    <Box className={Styles.LineChartBottomSectionCard}>
                                        <p>Total ROI</p>
                                        <h2>₹ {formatNumberWithCommas(totalROI)}</h2>
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 1, borderBottom: '3px solid  #102348 !important' }} className={Styles.LineChartBottomSectionCard}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                        <h2>₹ {formatNumberWithCommas(propertyValue)}</h2>
                                        <Chip className={Styles.TotalProfitInPercentageChip} label={`${totalProfitInPercentage} %`} />
                                    </Box>
                                    <p>Value of Investment after {tenure} years</p>
                                </Box>
                                <p className={Styles.DisclaimerText}>All the information shown here is for general information purposes only.
                                    Cuneate Services Private Limited (Cuneate) does not guarantee or warranty about the accuracy or completeness of this data.
                                    Investors are advised to do their own due diligence on individual opportunities before participating.</p>
                            </Box>

                        </Grid>
                    </Grid>


                </Box>
            </Container >
        </Box >

    );
};

export default InvestmentCalculator;
